/* src/components/AdminDashboard.css */
.dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .top-bar {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ccc;
  }
  
  .logout-button {
    padding: 10px 20px;
    background-color: #ff0000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background-color: #cc0000;
  }
  
  .content {
    display: flex;
    flex: 1;
  }
  
  .conversations-list {
    width: 30%;
    border-right: 1px solid #ccc;
    padding: 10px;
    overflow-y: auto;
  }
  
  .conversations-list h3 {
    margin-top: 0;
  }
  
  .conversation-item {
    cursor: pointer;
    margin: 10px 0;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
  }
  
  .conversation-item:hover {
    background-color: #e0e0e0;
  }
  
  .messages-list {
    width: 70%;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .messages-list h3 {
    margin-top: 0;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    flex: 1;
    overflow-y: auto;
  }
  
  .message-item {
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    max-width: 60%;
    word-wrap: break-word;
    position: relative;
  }
  
  .admin-message {
    background-color: #DCF8C6;
    text-align: right;
    margin-left: auto;
    border-top-right-radius: 0;
  }
  
  .user-message {
    background-color: #E1E1E1;
    text-align: left;
    margin-right: auto;
    border-top-left-radius: 0;
  }
  
  .message-info {
    font-size: 0.9em;
    color: #555;
  }
  
  .message-input-container {
    display: flex;
    padding: 10px 0;
  }
  
  .message-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .send-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #0056b3;
  }
  